var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    staticClass: "table-search",
    attrs: {
      "value": _vm.search,
      "append-icon": "$search",
      "placeholder": "検索...",
      "single-line": "",
      "hide-details": "",
      "dense": "",
      "outlined": "",
      "color": "#D7D6D6"
    },
    on: {
      "input": _vm.sync
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }