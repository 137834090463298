<template>
  <v-text-field
    :value="search"
    class="table-search"
    append-icon="$search"
    placeholder="検索..."
    single-line
    hide-details
    dense
    outlined
    color="#D7D6D6"
    @input="sync"
  ></v-text-field>
</template>

<script>
import { debounce } from "debounce";
export default {
  name: "TableSearch",
  props: {
    search: { type: String, default: "", required: true }
  },
  methods: {
    sync: debounce(function(val) {
      this.$emit("update:search", val);
    }, 500)
  }
};
</script>

<style>
.table-search fieldset {
  border: 1px solid #d7d6d6 !important;
}
</style>
