var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('v-container', {
    staticClass: "py-0 mb-2"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label text-capitalize font-weight-regular",
    attrs: {
      "height": "29",
      "depressed": "",
      "color": "secondary",
      "ripple": false
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$users")]), _vm._v(" ユーザー ")], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('TableSearch', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "update:search": [function ($event) {
        _vm.search = $event;
      }, _vm.getDataFromApi]
    }
  })], 1), _c('v-col', {
    staticClass: "pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "text": "",
      "color": "primary",
      "to": {
        name: 'UserCreate'
      }
    }
  }, [_c('span', {
    staticClass: "plus"
  }, [_vm._v("+")]), _vm._v("新規登録 ")])], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0 pb-0"
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.allUsers,
      "loading": _vm.loading,
      "total-records": _vm.userPagination ? _vm.userPagination.records_total : 0,
      "number-of-pages": _vm.userPagination ? _vm.userPagination.page : 0,
      "items-per-page": _vm.itemsPerPage
    },
    on: {
      "update:options": _vm.updateTable,
      "click:row": _vm.handleRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.avatar",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          staticClass: "white--text avatar-border",
          attrs: {
            "color": "avatar-bg-grey",
            "size": "38"
          }
        }, [item.avatar ? _c('v-img', {
          attrs: {
            "src": item.avatar
          }
        }) : _c('v-icon', {
          attrs: {
            "size": "18"
          }
        }, [_vm._v("$user")])], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(_vm._s(item.surname + " " + item.name))]), _c('div', {
          staticClass: "text-furigana-fade"
        }, [_vm._v(" " + _vm._s(item.furigana_surname + " " + item.furigana_name) + " ")])];
      }
    }, {
      key: "item.position",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', [_vm._v(_vm._s(item.position))])];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.email) + " ")];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.created_at) + " ")];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "color": "secondary",
            "icon": "",
            "to": {
              name: 'UserEdit',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "16"
          }
        }, [_vm._v("$edit")])], 1)];
      }
    }])
  }), _c('v-divider')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }