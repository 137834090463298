<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="pb-2">
          <v-container class="py-0 mb-2">
            <v-row align="center" class="">
              <v-col cols="auto">
                <v-btn
                  height="29"
                  class="label text-capitalize font-weight-regular"
                  depressed
                  color="secondary"
                  :ripple="false"
                >
                  <v-icon left>$users</v-icon>
                  ユーザー
                </v-btn>
              </v-col>

              <v-spacer></v-spacer>

              <v-col md="3" cols="12">
                <TableSearch
                  :search.sync="search"
                  @update:search="getDataFromApi"
                ></TableSearch>
              </v-col>

              <v-col cols="auto" class="pr-0">
                <v-btn
                  text
                  color="primary"
                  class="text-capitalize"
                  :to="{ name: 'UserCreate' }"
                >
                  <span class="plus">+</span>新規登録
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
          <Table
            :headers="headers"
            :items="allUsers"
            :loading="loading"
            :total-records="userPagination ? userPagination.records_total : 0"
            :number-of-pages="userPagination ? userPagination.page : 0"
            @update:options="updateTable"
            :items-per-page="itemsPerPage"
            @click:row="handleRowClick"
          >
            <template v-slot:item.id="{ item }">
              <div class="primary--text">{{ item.id }}</div>
            </template>
            <template v-slot:item.avatar="{ item }">
              <v-avatar
                color="avatar-bg-grey"
                size="38"
                class="white--text avatar-border"
              >
                <v-img v-if="item.avatar" :src="item.avatar"></v-img>
                <v-icon v-else size="18">$user</v-icon>
              </v-avatar>
            </template>
            <template v-slot:item.name="{ item }">
              <div>{{ item.surname+" "+item.name }}</div>
              <div class="text-furigana-fade">
                {{ item.furigana_surname+" "+item.furigana_name }}
              </div>
            </template>
            <template v-slot:item.position="{ item }">
              <div>{{ item.position }}</div>
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="secondary"
                icon
                :to="{ name: 'UserEdit', params: { id: item.id } }"
              >
                <v-icon size="16">$edit</v-icon>
              </v-btn>
            </template>
          </Table>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Table from "@/components/admin/partials/Table/Table";
import TableSearch from "@/components/admin/partials/Search/TableSearch";

export default {
  name: "index",
  components: { Table, TableSearch },
  created() {
    this.getDataFromApi();
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t("id"), align: "center", value: "id" },
        { text: "", align: "center", value: "avatar", sortable: false },
        {
          text: "氏名",
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: "役職",
          align: "left",
          value: "position",
          sortable: false
        },
        {
          text: "メールアドレス",
          align: "left",
          value: "email",
          sortable: false
        },
        {
          text: "登録日",
          align: "left",
          value: "created_at",
          sortable: false
        },
        {
          text: this.$t("actions"),
          align: "right",
          value: "action",
          sortable: false
        }
      ],
      search: "",
      deleteDialog: false,
      initialLoad: true,
      itemsPerPage:50
    };
  },
  computed: {
    ...mapGetters([
      "allUsers",
      "userPagination"
    ])
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    handleRowClick(item) {
      this.$router.push({ name: "UserEdit", params: { id: item.id } });
    },
    async getDataFromApi(e) {
      let params = {};

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.orderDesc = e.sortDesc[0] ? 0 : 1;
        params.orderBy = e.sortBy[0];
      } else {
        params.orderBy = "id";
        params.orderDesc = 0;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      } else {
        params.page = 1;
        params.paginate = 50;
      }

      await this.$store.dispatch("GET_ALL_USERS", params).then(() => {
        this.loading = false;
        this.initialLoad = false;
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
